/**
 * 0. All Pages
 * - 0.1 Masthead slider
 * - 0.2 Registration Popup
 * 1. Video Page
 * - 1.1 Responsive Video
 * - 1.2 Fix video on scroll
 * 2. Videos Listing Page
 * 3. Industry Insights Pages
 * 4. Templates & Tools Page
 * 5. PMPro Checkout Page (Register to event)
 */
(function($) {
  $(document).ready(function () {
/* ==========================================================================
 * 0. All Pages
 * ========================================================================== */

    /**
     * Set cookie via JS
     * 
     * @param string cookie_name
     * @param mixed cookie_value
     * @param int expire_in_days
     */
    var setCookie = function (cookie_name, cookie_value, expire_in_days) {
      var d = new Date();
      d.setTime(d.getTime() + (parseInt(expire_in_days) * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cookie_name + "=" + cookie_value + ";" + expires + ";path=/";
    };

    /**
     * Get cookie via JS
     * 
     * @param string cookie_name
     */
    var getCookie = function (cookie_name) {
      var name = cookie_name + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (' ' === c.charAt(0)) {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    };

    /**
     * Scale iframes in content
     */
    var scaleIframes = function() {
      $('.entry-content iframe').each(function () {
        var height = $(this).attr('height');
        var width = $(this).attr('width');
        if (height && width) {
          var ratio = width / height;
          $(this).css('height', '');
          $(this).css('width', '');
          $(this).css('max-width', '100%');
          $(this).css('height', $(this).width() / ratio);
        }
      });
    };

    /* 0.1 Masthead slider ================================================== */
    if ($('.masthead-banner .masthead .swiper-container').length) {
      new Swiper('.masthead-banner .masthead .swiper-container', {
        navigation: {
          nextEl: '.masthead-banner .masthead .swiper-container .swiper-button-next',
          prevEl: '.masthead-banner .masthead .swiper-container .swiper-button-prev'
        }
      });
    }
    /* 0.2 Registration Popup =============================================== */
    if ($('.mc-modal').length) {
      $('.mc-modal .mc-modal-close').on('click', function () {
        $(this).closest('.mc-modal').fadeOut(100);
      });
      $('.show-mc-modal').on('click', function (e) {
        e.preventDefault();
        $('.mc-modal').fadeIn(100);
      });
      if (!getCookie('mc_register_popup')) {
        setCookie('mc_register_popup', 1, 365);
        $('.mc-modal').fadeIn(100);
      }
    }
    /* 0.3 iFrames inside content =========================================== */
    scaleIframes();
    $(window).on('resize load', function () {
      scaleIframes();
    });

/* ==========================================================================
 * 1. Video Page
 * ========================================================================== */
    if ($('body').hasClass('page-template-template-video')) {
      /* 1.1 Responsive Video =============================================== */
      var scale_video = function () {
        if ($('.video-wrapper').length) {
          var ratio = 16 / 9;
          $('.video-wrapper iframe, .video-wrapper .video-background').each(function () {
            $(this).css('width' , '100%');
            $(this).css('height', $(this).width() / ratio);
          });
        }
      };
      scale_video();
      $(window).resize(function () {
        scale_video();
      });
      /* 1.2 Fix video on scroll ============================================ */
      var fix_video = function () {
        if ($('.video-wrapper .iframe-wrap').length) {
          var scroll_top = $(window).scrollTop();
          var video_top = $('.video-wrapper').offset().top;
          if ($('#primary').length && $('#secondary').length && $('#primary').offset().left !== $('#secondary').offset().left && scroll_top > video_top) {
            $('.video-wrapper .iframe-wrap').addClass('fixed')
            .css({
              'position': 'fixed',
              'left': $('#secondary').offset().left,
              'top': $('#wpadminbar').length ? $('#wpadminbar').height() + 'px' : '0',
              'width': $('#secondary').css('width')
            });
            return 1;
          }
          else {
            $('.video-wrapper .iframe-wrap').removeClass('fixed')
            .css({
              'position': 'static',
              'left': 0,
              'top' : 0,
              'width': '100%'
            });
            return 0;
          }
        }
      };
      $(window).scroll(function () {
        fix_video();
        scale_video();
      });
      if (fix_video()) {
        scale_video();
      }
    }

/* ==========================================================================
 * 2. Videos Listing Page
 * ========================================================================== */
    if ($('body').hasClass('page-template-template-videos-listing') || $('body').hasClass('page-template-template-section-builder')) {
      if ($('.video-listings .listing-content .single-video .single-video-row .sv-col.outline > a').length) {
        $('.video-listings .listing-content .single-video .single-video-row .sv-col.outline > a').click(function (e) {
          e.preventDefault();
          $('.video-listings .listing-content .single-video .single-video-row .sv-col.outline > .outlined-description').not($(this).siblings('.outlined-description')).removeClass('show');
          if ($(this).siblings('.outlined-description').length) {
            $(this).siblings('.outlined-description').toggleClass('show');
          }
        });
      }
    }
    $(document).click(function(event) {
      if(!$(event.target).closest('.video-listings .listing-content .single-video .single-video-row .sv-col.outline > *').length) {
        $('.video-listings .listing-content .single-video .single-video-row .sv-col.outline > a').next().removeClass('show');
      }
    });
/* ==========================================================================
 * 3. Industry Insights Pages
 * ========================================================================== */
    if ($('body').hasClass('page-template-template-industry-insights')) {
      if ($('.industry-insights-slider').length) {

        $('.industry-insights-slider').each(function () {
          var id = '#' + $(this).attr('id');
          new Swiper(id + ' .swiper-container', {
            slidesPerView: ($(this).hasClass('bigger-slides') ? 4 : 6),
            pagination: id + ' .swiper-pagination',
            paginationClickable: true,
            centeredSlides: false,
            breakpoints: {
              543: {
                slidesPerView: 2,
                spaceBetween: 0
              },
              991: {
                slidesPerView: ($(this).hasClass('bigger-slides') ? 3 : 4)
              }
            },
            navigation: {
              nextEl: id + ' .swiper-button-next',
              prevEl: id + ' .swiper-button-prev'
            }
          });
        });

        $('.industry-insights-slider .swiper-slide').on('click', function() {
          $('.slider-hover-content .slide-content').hide();
          $('#' + $(this).data('for')).fadeIn(300);
        });

        $('.industry-insights-slider .swiper-button-next, .industry-insights-slider .swiper-button-prev').on('mouseenter click hover', function(e) {
          e.stopPropagation();
        });

        $('.slider-hover-content .slide-content .close').on('click', function(e) {
          $(this).parents('.slide-content').fadeOut(300);
        });

        $('.slider-hover-content .slide-content').on('mouseenter', function(e) {
          e.stopPropagation();
        });

        $('.slider-hover-content .watch-video').fancybox();
      }
    }
/* ==========================================================================
 * 4. Templates & Tools Page
 * ========================================================================== */
    if ($('body').hasClass('page-template-template-templates-tools')) {
      $('.resource-click').click(function() {
        var target = '#' + $(this).data('target');
        $('.search-results-wrapper .initial, .search-results-wrapper .resource-download').hide();
        if ($(target).length) {
          $(target).closest('.resource-download').show();
        }
      });

      $('.js-find').on('keyup change blur', function () {
        var search = $(this).val();
        var wrapper = $(this).closest('.search-field-wrapper').next();
        if (search) {
          wrapper.find('.select-category').hide();
          wrapper.find('.select-resource').each(function () {
            if ($(this).text().toLowerCase().indexOf(search.toLowerCase()) !== -1) {
              $(this).show();
            }
            else {
              $(this).hide();
            }
          });
        }
        else {
          wrapper.find('.select-category, .select-resource').show();
        }
      });
      $('.js-find-search-icon').on('click', function () {
        $(this).prev().trigger('change');
      });
    }
/* ==========================================================================
 * 5. PMPro Checkout Page (Register to event)
 * ========================================================================== */
    if ($('body').hasClass('pmpro-checkout')) {
      if ($('#team_leader_n').length) {
        var check_tl_dropdown = function () {
          var tl_dropdown = $('#team_leader_n');
          for (var n = 3; n <= 4; n++) {
            if ($('#first_name_' + n).val() || $('#last_name_' + n).val() || $('#team_member_email_' + n).val() || $('#team_member_confirm_email_' + n).val()) {
              tl_dropdown.find('[value="' + n + '"]').prop('disabled', false);
            }
            else {
              tl_dropdown.find('[value="' + n + '"]').prop('disabled', true);
              if (tl_dropdown.val() == n || !tl_dropdown.val()) {
                tl_dropdown.val('1');
              }
            }
          }
        };
        check_tl_dropdown();
        $('#first_name_3, #last_name_3, #team_member_email_3, #team_member_confirm_email_3, #first_name_4, #last_name_4, #team_member_email_4, #team_member_confirm_email_4').on('keyup change blur', function() {
          check_tl_dropdown();
        });
      }

      var recalculate_team_registration = function () {
        var form_data_obj = {};
        var form_data = $('#pmpro_form').serializeArray();
        $.each(form_data, function(index, el) {
          form_data_obj[el.name] = el.value;
        });
        $.ajax({
          type: 'POST',
          url: window.ajax_global.ajax_url,
          data: {
            action: 'recalculate',
            form_data: form_data_obj
          },
          success: function (response) {
            if ($('#base-registration-fee').length && response.data.base_registration_fee) {
              $('#base-registration-fee').val(response.data.base_registration_fee);
            }
            if ($('#credit-applied-fee').length && response.data.credit_applied) {
              $('#credit-applied-fee').val(response.data.credit_applied);
            }
            if ($('#final-registration-fee').length && response.data.final_registration_fee) {
              $('#final-registration-fee').val(response.data.final_registration_fee);
            }
            if ($('#total-amount-charge').length && response.data.final_registration_fee) {
              $('#total-amount-charge').html(response.data.final_registration_fee);
            }
          }
        });
      };
      recalculate_team_registration();
      $(document).on('click', '.js-do-recalculate-team-amount', function (e) {
        e.preventDefault();
        recalculate_team_registration();
      });
      $('#last_name_2, #bemail, #team_member_email_2, #team_member_email_3, #team_member_email_4, #discount_code').on('change', function () {
        recalculate_team_registration();
      });
      $('#discount_code_button').on('click', function() {
        recalculate_team_registration();
      });

      var university_other = function() {
        if ($('#university').val().toLowerCase() === 'other') {
          $('#university_other_div').show();
        }
        else {
          $('#university_other_div').hide();
        }
      };
      university_other();
      $('#university').on('change', function() {
        university_other();
      });

      if ($('#discount_code').length) {
        $('#discount_code').attr('autocomplete', 'off');
      }
    }
  });
})(jQuery);